import { defineStore } from 'pinia'

export const useRoutesStore = defineStore({
    id: 'routes',
    state: () => ({
        route: [],
        routeInfo: [],
        routeDangerObjects: [],

    }),
    getters: {
        getRoute: (state) => state.route
    },
    actions: {
        addRoutePoints(val) {
            this.route = val
        },
        addRouteInfo(val) {
            this.routeInfo = val
        },
        addRouteDangerObjects(val) {
            this.routeDangerObjects = val
        }
    },
    persist: false
})
