import {myAxios} from "@/services/myAxios";
import {file} from "paths.macro";
// import {commit} from "lodash/seq";
import axios from "axios";
import Vue from "vue";
import store from "@/store";
import {eventBus} from "@/main";
import localforage from "localforage";
import {keepRoute} from "@/helpers/lsHelpers";
let LsRoute = localStorage.getItem('route')

let LsPoints
let LsSettings
let LsZones
let LsInfo

if (LsRoute){
    if (JSON.parse(LsRoute)['route_temp_1']){
        LsPoints = JSON.parse(LsRoute)['route_temp_1']
    }
    if (JSON.parse(LsRoute)['route_temp_2']){
        LsSettings = JSON.parse(LsRoute)['route_temp_2']
    }
    if (JSON.parse(LsRoute)['route_temp_3']){
        LsZones = JSON.parse(LsRoute)['route_temp_3']
    }
    if (JSON.parse(LsRoute)['route_temp_4']){
        LsInfo = JSON.parse(LsRoute)['route_temp_4']
    }
}
const initialState = {
    progress: {
        isCalculating: false,
        calculatingProgress: 0,
    },
    routeWithInfo: [],
    route: LsPoints ? LsPoints : [],
    routePrevious: [],
    segment1Points: null,
    segment2Points: null,
    route_settings: LsSettings ? LsSettings : {
        draught: 10,
        height: 20,
        radius: 120,
        xtel: 120,
        xter: 120,
        recommended: false,
        speed: 10,
        ratio: 0.7,
        shallowratio: 5,
        shallowstrength: 5,
        avoiddistance: 120,
        date: '',
        time: ''
    },
    bounds: null,
    length: '',
    length_manual: '',
    dangers: [],
    dangers_filtered: [],
    dangers_keys: {
        categories: null,
    },
    dangers_legs: [],
    debug: [],
    info: LsInfo ? LsInfo : [],
    use_for_order: false,
    movingWP: -1,
    nogoZones: LsZones ? LsZones : {
        zones: []
    }
}

export const routes = {
    namespaced: true,
    state: initialState,
    actions: {},
    mutations: {
        setCalculatingStatus(state, status) {
            state.progress.isCalculating = status;
            if (status) {
                state.progress.calculatingProgress = 0;
            }
        },
        setProgress(state, progress) {
            state.progress.calculatingProgress = progress;
        },
        setRoute(state, payload) {
            state.routeWithInfo = payload.route;
            state.length = Number(payload.length).toFixed(2);
            state.dangers = payload.dangers;
            state.debug = payload.debug

            let filtered = payload.dangers.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id
                    ))
            )
            state.dangers_filtered = Object.freeze(filtered)

            // eventBus.$emit('draw-all-nogo-objects2')
            store.commit("fickle/clearMetric")

            let allrequests = []

            filtered.forEach(item => {
                const req = myAxios.get(`https://mwend.bgeo.fi/api/v1/objectinfo?id=${item.id}`).then(resp => {
                    store.commit("fickle/setMetric", resp.data)});
                allrequests.push(req);
            })

            Promise.allSettled(allrequests).then((res) => {
                console.log("iiii", res);
                eventBus.$emit('draw-all-nogo-objects');
                // console.log("off, loadUserData DONE")
                // store.commit("fickle/setUserDataLoading", false)
                // localStorage.setItem('proto', JSON.stringify(store.state.proto));
                // localforage.setItem('proto', JSON.stringify(store.state.proto));
                // localStorage.setItem('transactions', JSON.stringify(store.state.transactions));
                // localStorage.setItem('distributor', JSON.stringify(store.state.distributor));
                // resolve(true)
            })


            // filtered.forEach(item => {
            //     myAxios.get(`https://mwend.bgeo.fi:6004/api/v1/objectinfo?id=${item.id}`)
            //         .then(resp => {
            //             store.commit("fickle/setMetric", resp.data)
            //             eventBus.$emit('draw-all-nogo-objects')
            //             // eventBus.$emit("draw-nogo-object", resp.data)
            //             // this.prepareInfo(id)
            //         });
            // })

            let mySet = new Set()
            payload.dangers.forEach(item => {
                mySet.add(item.name)

                if (item.isDanger) {
                    state.dangers_legs[item.legIndex] = true
                } else {
                    if (state.dangers_legs[item.legIndex] !== true) {
                        state.dangers_legs[item.legIndex] = false
                    }
                }
            })
            Vue.set(state.dangers_keys, "categories", mySet );

            state.info = payload.info;
        },
        setRoutePointsBackup(state) {
            state.routePrevious = state.route;
        },
        setRoutePoints(state, payload) {
            state.route = payload;


            keepRoute("route_temp_1", payload)
        },
        setRoutePointsDetailsFlag(state, index) {
            state.route.forEach(item => {
                item._showDetails = false
            })
            state.route[index]._showDetails = true;
        },
        clearRoutePointsDetailsFlag(state, index) {
            state.route.forEach(item => {
                item._showDetails = false
            })
        },
        setRouteLengthManual(state, length) {
            state.length_manual = Number(length).toFixed(2);
        },
        setRouteSettings(state, payload) {
            state.route_settings.draught = payload.draught
            state.route_settings.height = payload.height
            state.route_settings.radius = payload.radius
            state.route_settings.xtel = payload.xtel
            state.route_settings.xter = payload.xter
            state.route_settings.recommended = payload.recommended
            state.route_settings.speed = payload.speed
            state.route_settings.date = payload.date
            state.route_settings.time = payload.time
            state.route_settings.ratio = payload.ratio
            state.route_settings.shallowratio = payload.shallowratio
            state.route_settings.shallowstrength = payload.shallowstrength
            state.route_settings.avoiddistance = payload.avoiddistance

        },
        setRouteBounds(state, bounds) {
            state.bounds = bounds;
        },
        setRouteSegment1Points(state, data) {
            state.segment1Points = data;
        },
        setMovingWP(state, index) {
            state.movingWP = index;
        },
        setRouteSegment2Points(state, data) {
            state.segment2Points = data;
        },
        setZone(state, data) {
            state.nogoZones.zones.push(data)
        },
        deleteZone(state, name) {
            let index = state.nogoZones.zones.findIndex(p => p.name == name);
            state.nogoZones.zones.splice(index, 1);
        },
        useRouteForOrder(state, flag) {
            state.use_for_order = flag;
        },
        resetStorage(state) {
            //state = initialState;
            //Object.assign(state, initialState)
            state.route = [];
            state.segment1Points = null;
            state.segment2Points = null;
            state.dangers = [];
            state.dangers_keys.categories = null;

            state.dangers_legs = []
            state.info = [];
            // state.length = '';
            state.progress.isCalculating = false;
            state.progress.calculatingProgress = 0;
            state.use_for_order = false;
            // state.nogoZones.zones = [];
        }
    }
}