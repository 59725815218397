import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "@/store";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function createVoyagePlan() {
    let rows = []

    let columns = [];
    columns.push({ text: '#', style: 'tableHeader'});
    columns.push({ text: 'Coordinates', style: 'tableHeader'});
    columns.push({ text: 'ETA', style: 'tableHeader'});

    rows.push(columns)

    store.state.routes.route.forEach((item, i) => {
        let values = []
        values.push({ text: i, style: 'tableText'});
        values.push({ text: item.lat.toFixed(6) + "\n" + item.lon.toFixed(6), style: 'tableText'});
        values.push({ text: "", style: 'tableText'});
        rows.push(values)
    })

    let dd = {
        pageOrientation: 'landscape',
        content: [
            {
                table: {
                    widths: ['*', '*', '*'],
                    headerRows: 1,
                    body: [
                        [{text: 'PILOTAGE PLAN', style: 'tableHeader', colSpan: 3, alignment: 'center'}, {}, {}],
                        ['Date:', {text: 'Route Name:', colSpan: 2}, {}],
                        ['Tide PILOTAGE Port:', {rowSpan: 2, text: 'Additional Notes:'}, 'Total distance:'],
                        [' ', ' ', ' '],
                        [' ', ' ', ' '],
                        [' ', ' ', ' '],
                    ]
                }
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['auto', 'auto', '*'],
                    body: rows
                }
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            },
            tableText: {
                fontSize: 12,
                color: 'gray'
            }
        },
        defaultStyle: {
            // alignment: 'justify'
        }
    }

    pdfMake.createPdf(dd).open({});

}